import Header from './components/layouts/Header.js'
import Footer from './components/layouts/Footer.js'

function App() {
  return (
    <div className="App">
      <Header />
      <main className="hero">
        <div className="container mx-auto">
          <div className="flex flex-col gap-6 py-14 md:py-28">
            <p className="uppercase tracking-widest text-default-light">Introducing</p>
            <h1 className="text-3xl xl:w-3/5 md:text-5xl text-white font-bold">
            <span className="block"><span className="gradient-text">RektorDB</span>:</span> 
            <span className="block">The ultimate solution for high-dimensional vectors</span></h1>
            <p className="md:w-3/4 xl:w-1/2 text-default-light-dark">Rektor is a highly-scalable, high-performance vector database designed to store and query large collections of high-dimensional vectors. It is designed to be used in a wide variety of applications, from natural language processing to computer vision to recommendation systems.</p>
            <button className="primary self-start">Get started</button>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
