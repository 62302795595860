import Logo from '../../components/common/Logo.js'

function Header() {
    return (
        <header className="header py-4 text-white">
          <div className="container mx-auto flex flex-wrap gap-2 sm:flex-nowrap justify-between items-center">
            <Logo />
            <div className="flex gap-4 text-default-light-dark whitespace-nowrap">
              <a href="#">Docs</a>
              <a href="#">How to</a>
              <a href="#">Github</a>
            </div>
          </div>
      </header>
    )
}

export default Header