function Logo() {
    return (
        <div className="logo flex items-center gap-1">
            <img className="h-8" src="logo.svg" alt="RektorDB logo" />
            <div className="text-2xl">
                <span className="font-light">Rektor</span>
                <span className="font-bold">DB</span>
            </div>
        </div>
    )
}

export default Logo