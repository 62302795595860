function Footer() {
    return (
        <footer>
            <div className="container mx-auto flex flex-col md:flex-row justify-center gap-10 py-14 md:py-20">
                <div className="flex flex-1 flex-col gap-5">
                    <h2 className="text-white text-2xl md:text-3xl font-bold">Features</h2>
                    <ul className="list-disc px-4 text-default-light-dark">
                        <li>Efficient storage and retrieval of high-dimensional vectors</li>
                        <li>Support for similarity search and nearest neighbor queries</li>
                        <li>Highly scalable and can handle large datasets</li>
                        <li>Simple and easy-to-use API for interacting with the database</li>
                        <li>Written in Go for fast performance and minimal resource usage</li>
                    </ul>
                </div>
                <div className="flex flex-1 flex-col gap-5">
                    <h2 className="text-white text-2xl md:text-3xl font-bold">Installation</h2>
                    <p className="text-default-light-dark">Rektor is still in development and not yet available for installation. However, we are actively seeking investors to help us bring Rektor to market. If you are interested in learning more about our vision and becoming a part of our team, please contact us at <a href="mailto:admin@rektor.tech">admin@rektor.tech</a> or visit our <a href="#">github</a>.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer